import { OurIcons } from "./OurIcons"
import { NavBottomLinksInterface, TitledListItemInterface } from "./Interfaces"
import { LocalImages, WebImages } from "./Images"

export class GlobalVars {

    //
    static primaryColor = "#304FFE"
    static otherColors: Array<string> = [
        '#304FFE','#D50000', '#C51162', '#AA00FF', '#6200EA', 
        '#2962FF', '#0091EA', '#00B8D4', '#00BFA5', '#00C853',
        '#64DD17', '#AEEA00', '#FFD600', '#FFAB00', '#FF6D00',
        '#DD2C00'
    ]

    //
    static myName = 'Marc Berkson'
    static myOccupation = 'Chiropractor'
    static myDetails = 'Dr. Berkson offers manual and instrument chiropractic adjustments, cold laser treatments, and pain management. Lower back pain is one of the leading reasons people miss work, abuse pain killers and require clostly surgical procedures. In addition, longer work days in the post covid world have increased stress and anxiety. Routine chiropractic care can help with both.'
    static myLeftAvatarImage = null
    static myRightAvatarImage = './family.jpg'
    static helperTexts = "We have a form available for you to write down any queries you may have, which will be sent directly to me. Would you like to view the form now?"
    static helperFormLink = ""

    static bottomLinks: Array<NavBottomLinksInterface> = [
        {
            svgPath: OurIcons.whatsappIcon,
            link: 'tel:646-592-0226'
        },
        {
            svgPath: OurIcons.emailIcon,
            link: 'mailto:info@drmarcberkson.com'
        }
    ]

    static skills: Array<TitledListItemInterface> = [
        {
            text: "For Home",
            arr: [
                {
                    title: 'Regular Chiropractic adjustment',
                    logo: 'https://seeklogo.com/images/C/chiropractic-logo-9FC1B559C7-seeklogo.com.png',
                },
                {
                    title: 'Cold Laser therapy for pain and inflammation',
                    logo: LocalImages.laser,
                },
                {
                    title: 'Percussion Massage Therapy',
                    logo: LocalImages.vibracussor,
                },
                {
                    title: 'Impulse IQ adjusting instrument',
                    logo: LocalImages.impulse,
                },
                {
                    title: 'Creating a personal wellness plan and profile for short and long term health goals',
                    logo: 'https://media.istockphoto.com/id/1039118842/vector/wellness-an-fitness-vector-design-template.jpg?s=612x612&w=0&k=20&c=l5MaDvhkXyByhiOdqphA-QbPa_69dPgKtvZzyyQ1-eA='
                }
            ]
        },
        {
            text: "For Offices",
            arr: [
                {
                    title: 'Comprehensive evaluation of overall workplace wellness',
                    logo:'https://media.istockphoto.com/id/1039118842/vector/wellness-an-fitness-vector-design-template.jpg?s=612x612&w=0&k=20&c=l5MaDvhkXyByhiOdqphA-QbPa_69dPgKtvZzyyQ1-eA='
                },
                {
                    title: 'Lunch and Learn lectures on topics such as stress management, ergonomics, and overall workplace wellness',
                    logo: 'https://seeklogo.com/images/T/the-office-logo-3A865A26A3-seeklogo.com.png'
                },
                {
                    title: 'One on One health and wellness assessments',
                    logo: './assessmentlogo.jpg'
                },
                {
                    title: 'Traditional Chiropractic care without having to leave the office',
                    logo: 'https://seeklogo.com/images/C/chiropractic-logo-9FC1B559C7-seeklogo.com.png'
                },
                {
                    title: 'Cold Laser therapy for pain and inflammation',
                    logo: LocalImages.laser,
                },
                {
                    title: 'Percussion Massage Therapy',
                    logo: LocalImages.vibracussor,
                },
                {
                    title: 'Impulse IQ adjusting instrument',
                    logo: LocalImages.impulse,
                }
            ]
        }
        
    ]

    static works: Array<TitledListItemInterface> = [
        {
            text: "Tools",
            arr: [

            ]
        },
        
    ]

    static contacts: Array<TitledListItemInterface> = [
        {
            text: "Contact me",
            arr: [
                {
                    title: 'Phone',
                    logo: './phone_icon.png',
                    desc: '646-592-0226',
                    link: 'tel:6465920226'
                },
                {
                    title: 'Email',
                    logo: 'https://cdn3d.iconscout.com/3d/premium/thumb/email-address-4437044-3684811.png',
                    desc: 'info@drmarcberkson.com',
                    link: 'mailto:info@drmarcberkson.com'
                },
                {
                    title: 'Address',
                    logo: './house.jpg',
                    desc: 'Bergenfield NJ 07621',
                    link: 'https://maps.app.goo.gl/mZYp8Uc9Lkt1HWvTA'
                }
            ]
        },
        
    ]
}


