export class LocalImages {
    static avatar = './avatar.png'
    static avatar_with_laptop = "./avatar.png"
    static vibracussor = './vibracussor.jpg'
    static laser = './EVRL-Laser.jpg'
    static impulse = './Impulse_IQ.jpg'
}

export class WebImages {
    static giftIcon = 'https://user-images.githubusercontent.com/6418354/155841091-9bdc3b05-fabd-4e58-a0fd-fe90b08275e4.png'
    static flutterIcon = 'https://www.vectorlogo.zone/logos/flutterio/flutterio-icon.svg'
}